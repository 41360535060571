import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { MaterialInput } from '../../components/Input'
import useForgotPassword from '../../data/userForgotPassword'
import { ForgotPasswordFormField } from './interface'
import * as styles from './style'

const ForgotPassword: FunctionComponent = () => {
    const { push } = useHistory()

    const { mutate: resetPassword, loading } = useForgotPassword()

    const submitEmail = async ({ email }: ForgotPasswordFormField) => {
        const data = await resetPassword({
            email
        })

        if (data.success) {
            push('/verifique-seu-email')
        }
    }

    return (
        <styles.Background>
            <styles.ContentPanel>
                <Form onFinish={submitEmail}>
                    <styles.Title>Esqueceu sua senha?</styles.Title>
                    <styles.Subtitle>Acontece ;D</styles.Subtitle>
                    <Box mt={8}>
                        <Field name="email" rules={[{ required: true }]}>
                            <MaterialInput label="E-mail" fullWidth />
                        </Field>
                    </Box>
                    <Box mt={20}>
                        <Button fullWidth type="submit" loading={loading}>
                            Continuar
                        </Button>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default ForgotPassword
