import React, { FunctionComponent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { useHistory } from 'react-router'
import * as storage from '../../utils/storage'
import * as styles from './styles'

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children?: React.ReactElement<unknown, any>
        },
        ref: React.Ref<unknown>
    ) => {
        return <Slide direction="up" ref={ref} {...props} />
    }
)

const AlertDialogSlide: FunctionComponent = () => {
    const [open, setOpen] = React.useState(false)
    const { push } = useHistory()

    const handleClose = () => {
        setOpen(false)
        storage.clear()
        push('/cadastrar-cpf')
    }

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <styles.Title>Acesso negado :(</styles.Title>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <styles.Text>Encontramos inconsistências nos dados informados.</styles.Text>
                        Precisa de ajuda? Entre em contato com o privacidade@docway.co
                    </DialogContentText>
                </DialogContent>
                <styles.ButtonModal onClick={handleClose} color="primary">
                    ENTENDI
                </styles.ButtonModal>
            </Dialog>
        </div>
    )
}

export default AlertDialogSlide
