import React, { FunctionComponent } from 'react'
import * as styles from './style'
import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { useHistory } from 'react-router-dom'
import useLogin from '../../data/useLogin'
import useCheckRegisterUser from '../../data/useCheckRegisterUser'
import { useEffect } from 'react'
import { RegisterNameFormFields } from './interfaces'
import * as storage from '../../utils/storage'

const RegisterName: FunctionComponent = () => {
    const { refetch: checkUserRegister, loading, data } = useCheckRegisterUser()

    const { push } = useHistory()

    let dataErro = storage.getItem('dataErro')

    const { error } = useLogin()

    const submitName = async ({ name }: RegisterNameFormFields) => {
        const cpf = storage.getItem('cpf')

        await checkUserRegister({
            queryParams: {
                Cpf: cpf || '',
                Name: name,
                DataType: 1
            }
        })

        storage.setItem('name', name)
    }

    useEffect(() => {
        if (data) {
            push('/cadastrar-data-nasc')
        } else {
            setDataError()
        }
    }, [data])

    const setDataError = () => {
        if (data == false) {
            dataErro = '1'
            storage.setItem('dataErro', dataErro)
            push('/cadastrar-data-nasc')
        }
    }
    return (
        <styles.Background>
            {error && <styles.ErrorOverlay id="error-overlay">CPF inválido</styles.ErrorOverlay>}
            <styles.ContentPanel error={!!error}>
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>Seja bem-vindo ao Portal de Privacidade da Docway</styles.textLogo>
                <styles.Title>Qual seu nome completo ?</styles.Title>
                <Form onFinish={submitName}>
                    <Box mt={4}>
                        <Field name="name">
                            <MaterialInput
                                id="name"
                                label="Nome completo"
                                type="text"
                                fullWidth
                                error={!!error}
                            />
                        </Field>
                    </Box>
                    <Box mt={6}>
                        <styles.Login
                            error={!!error}
                            type="submit"
                            fullWidth
                            loading={loading}
                            onclick={setDataError}
                        >
                            Continuar
                        </styles.Login>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default RegisterName
