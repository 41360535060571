import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
`

export const Panel = styled.div`
    width: 615px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    padding: 60px 20px;
    box-shadow: 0 1px 4px 0 rgba(174, 174, 174, 0.5);
    position: relative;
    height: 300px;
`

export const CloseButton = styled.span`
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
`

export const Title = styled.p`
    font-size: 28px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.green['100']};
`
