import React, { FunctionComponent } from 'react'
import * as styles from './style'
import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { useHistory } from 'react-router-dom'
import useSendConfirmationCode from '../../data/useSendConfirmationCode'
import { RegisterEmailFormFields } from './interfaces'
import * as storage from '../../utils/storage'
import AccessModal from '../../components/ModalAccessBlocked'
const RegisterBirthDate: FunctionComponent = () => {
    const { mutate: sendConfirmationCode, loading } = useSendConfirmationCode()

    const dataError = storage.getItem('dataErro')

    const { push } = useHistory()

    const submitEmail = async ({ email }: RegisterEmailFormFields) => {
        const name = storage.getItem('name')

        const response = await sendConfirmationCode({
            Email: email,
            Name: name || ''
        })

        storage.setItem('email', email)
        push('/verifica-codigo')
    }

    return (
        <styles.Background>
            <styles.ContentPanel>
                {dataError == '1' && <AccessModal />}
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>Seja bem-vindo ao Portal de Privacidade da Docway</styles.textLogo>
                <styles.Title>Qual seu e-mail?</styles.Title>
                <Form onFinish={submitEmail}>
                    <Box mt={4}>
                        <Field name="email">
                            <MaterialInput id="email" label="E-mail" type="email" fullWidth />
                        </Field>
                    </Box>

                    <Box mt={6}>
                        <styles.Login type="submit" fullWidth loading={loading}>
                            Continuar
                        </styles.Login>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default RegisterBirthDate
