import { FunctionComponent, useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { User } from '../../types/User'
import { AuthContextProps } from './interface'
import * as storage from '../../utils/storage'
import { useEffect } from 'react'
import decode from 'jwt-decode'

const AuthContext = createContext<AuthContextProps>({
    user: undefined,
    isAuthenticated: false,
    authenticate: () => null,
    logout: () => null
})

const AuthProvider: FunctionComponent = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!storage.getItem('token'))
    const [user, setUser] = useState<User>()

    const authenticate = (token: string): void => {
        const userDecoded = decode<any>(token)

        const newUser = {
            id: userDecoded.id,
            name: userDecoded.userName,
            email: userDecoded.email,
            cpf: userDecoded.cpf,
            rg: userDecoded.rg,
            matricula: userDecoded.matricula,
            gender: userDecoded.gender,
            telefone: userDecoded.telefone,
            endereco: userDecoded.endereco,
            convenio: userDecoded.convenio
        }

        setUser(newUser)

        storage.setItem('token', token)
        storage.setItem('user', JSON.stringify(newUser))

        setIsAuthenticated(true)
    }

    const logout = (): void => {
        setIsAuthenticated(false)
        setUser(undefined)

        storage.clear()
    }

    useEffect(() => {
        const token = storage.getItem('token')
        const userDecoded = storage.getItem<User>('user')

        if (token && userDecoded) {
            setUser(userDecoded)
            setIsAuthenticated(true)
        }
    }, [])

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                authenticate,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext)

export { useAuth, AuthContext, AuthProvider }
