import styled from 'styled-components'

export const Background = styled.div`
    background-image: url('/assets/img/bg.png');
    background-position: center top;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    position: absolute;
    min-width: 1024px;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    @media only screen and (max-width: 728px) {
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
    }
`

export const ContentPanel = styled.div`
    width: 600px;
    height: 500px;
    padding: 60px 75px 48px;
    border-radius: 12px;
    background-color: #fdfdfd;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 728px) {
        width: 200px;
        padding: 20px 35px 18px;
    }
`

export const Title = styled.h3`
    align-self: center;
    text-align: center;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.green['100']};
    font-size: 28px;
`

export const Subtitle = styled.h4`
    align-self: center;
    text-align: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.green['100']};
    font-size: 28px;
`

export const Logo = styled.img`
    align-self: center;
    height: 42px;
`

export const ForgotPasswordContainer = styled.div`
    margin: 35px 0px;
`

export const ForgotPassword = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey['100']};
    opacity: 0.6;
    a {
        color: ${({ theme }) => theme.colors.grey['100']};
    }
`

export const SpaceTop = styled.div`
    margin-top: 35px;
`
