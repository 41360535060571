import styled from 'styled-components'
import { Checkbox as MaterialCheckbox } from '@material-ui/core'

export const Label = styled.p`
    color: ${({ theme }) => theme.colors.grey['100']} !important;
    font-size: 16px;
    font-weight: bold;
    margin: 0px !important;
    margin-bottom: 8px !important;
`

export const Checkbox = styled(MaterialCheckbox)`
    padding: 0px !important;
    font-size: 16px;
    margin-right: 8px !important;
`

export const CheckboxLabel = styled.p`
    margin: 0px !important;
    font-size: 16px;
`
