import { TextField, withStyles } from '@material-ui/core'
import { Colors } from '../../../constants/Colors'

export default withStyles({
    root: {
        '& label.Mui-focused': {
            color: Colors.green['100']
        },
        '& label.Mui-focused.Mui-error': {
            color: Colors.grey['100']
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.green['100']
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: Colors.red['100']
        }
    }
})(TextField)
