import { FunctionComponent, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { CheckboxGroupProps } from './interfaces'
import * as styles from './styles'
import { Colors } from '../../constants/Colors'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useEffect } from 'react'

const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({ options, value, onChange }) => {
    const [selecteds, setSelecteds] = useState<number[]>([])

    const select = (index: number): void => {
        if (selecteds.includes(index)) {
            setSelecteds(selecteds.filter(selected => selected !== index))
        } else {
            setSelecteds(selecteds.concat(index))
        }
    }

    useEffect(() => {
        if (onChange) {
            onChange(selecteds.map(selected => options[selected].value))
        }
    }, [selecteds])

    return (
        <>
            <Grid container>
                <Grid item xs>
                    {options.map((option, index) => (
                        <>
                            {index % 2 === 0 && (
                                <Box
                                    key={option.value}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <styles.Checkbox
                                        icon={<MdCheckBoxOutlineBlank size={20} />}
                                        checkedIcon={
                                            <MdCheckBox size={20} fill={Colors.green['100']} />
                                        }
                                        value={option.value}
                                        checked={selecteds.includes(index)}
                                        onChange={() => select(index)}
                                    />
                                    <styles.CheckboxLabel onClick={() => select(index)}>
                                        {option.label}
                                    </styles.CheckboxLabel>
                                </Box>
                            )}
                        </>
                    ))}
                </Grid>
                <Grid item xs>
                    {options.map((option, index) => (
                        <>
                            {index % 2 === 1 && (
                                <Box
                                    key={option.value}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <styles.Checkbox
                                        icon={<MdCheckBoxOutlineBlank size={20} />}
                                        checkedIcon={
                                            <MdCheckBox size={20} fill={Colors.green['100']} />
                                        }
                                        value={option.value}
                                        checked={selecteds.includes(index)}
                                        onChange={() => select(index)}
                                    />
                                    <styles.CheckboxLabel onClick={() => select(index)}>
                                        {option.label}
                                    </styles.CheckboxLabel>
                                </Box>
                            )}
                        </>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}

export default CheckboxGroup
