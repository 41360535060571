import { FunctionComponent } from 'react'
import * as styles from './styles'
import { IoMdClose } from 'react-icons/io'
import { Colors } from '../../constants/Colors'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router'

const ChangePasswordSuccess: FunctionComponent = () => {
    const { push } = useHistory()

    return (
        <styles.Container>
            <styles.Panel>
                <styles.CloseButton onClick={() => push('/trocar-senha')}>
                    <IoMdClose fill={Colors.green['100']} size={30} />
                </styles.CloseButton>
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <styles.Title>Senha alterada com sucesso</styles.Title>
                </Box>
            </styles.Panel>
        </styles.Container>
    )
}

export default ChangePasswordSuccess
