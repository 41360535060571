import { FunctionComponent } from 'react'
import { AuthProvider } from './contexts/AuthContext'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'
// import DayJS from '@date-io/dayjs'
// import locale from 'dayjs/locale/pt-br'
import ApiProvider from './components/ApiProvider'

const Providers: FunctionComponent = ({ children }) => {
    return (
        <AuthProvider>
            <ApiProvider>{children}</ApiProvider>
        </AuthProvider>
    )
}

export default Providers
