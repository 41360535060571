import styled from 'styled-components'

export const Img = styled.img`
    width: 363px;
    height: 319px;
    margin: 0 0 0 220px;
    object-fit: contain;
    @media only screen and (max-width: 728px) {
        width: 163px;
        height: 119px;
    }
`
export const ContentPanel = styled.div`
    display: flex;
    padding: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    @media only screen and (max-width: 728px) {
        display: block;
    }
`
export const Elements = styled.div`
    display: inline-block;
    text-align: center;
    @media only screen and (max-width: 728px) {
        display: block;
    }
`
export const Title = styled.h3`
    align-self: left;
    text-align: left;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey['100']};
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`

export const Subtitle = styled.h4`
    align-self: left;
    text-align: left;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey['100']};
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
`

export const CreateAccount = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    max-height: 48px;
    border: 2px solid ${({ theme }) => theme.colors.green['100']};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.green['100']};
    padding: 14px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`
