import { useMutate } from 'restful-react'
import { ForgotPasswordResponse } from '../../pages/forgot-password/interface'
import { ForgotPasswordParameters } from './interface'

const useForgotPassword = () => {
    return useMutate<ForgotPasswordResponse, any, any, ForgotPasswordParameters>(
        'POST',
        '/user/sendresetpasswordlgpd'
    )
}

export default useForgotPassword
