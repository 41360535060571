export const Colors = {
    white: '#FDFDFD',
    greyLight: '#F1F1F1',
    green: {
        '100': '#8FD16A',
        '80': '#A5DA88',
        '60': '#BCE3A6',
        '40': '#D2EDC3',
        '20': '#E9F6E1',
        dark: '#5E9F3C'
    },
    blue: {
        '100': '#59BEC9',
        '80': '#7ACBD4',
        '60': '#9BD8DF',
        '40': '#BDE5E9',
        '20': '#DEF2F4'
    },
    yellow: {
        '100': '#F8DD50',
        '80': '#F9E473',
        '60': '#FBEB96',
        '40': '#FCF1B9',
        '20': '#FEF8DC'
    },
    red: {
        '100': '#CE4E4E',
        '80': '#D87171',
        '60': '#E29595',
        '40': '#EBB8B8',
        '20': '#F5DCDC'
    },
    orange: {
        '100': '#F7B500',
        '80': '#F9C433',
        '60': '#FAD366',
        '40': '#FCE199',
        '20': '#FDF0CC'
    },
    grey: {
        '100': '#707070',
        '80': '#8D8D8D',
        '60': '#A9A9A9',
        '40': '#C6C6C6',
        '20': '#E2E2E2'
    },
    primary: '#8FD16A',
    primaryDark: '#5e9f3c',
    secondary: '#FDFDFD'
}
