import styled from 'styled-components'

export const Background = styled.div`
    background-image: url('/assets/img/bg.png');
    background-position: center top;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    position: absolute;
    min-width: 1024px;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    @media only screen and (max-width: 728px) {
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
    }
`
export const Title = styled.h1`
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.grey['100']};
`

export const LoginPanel = styled.div`
    width: 600px;
    height: 583px;
    padding: 60px 75px 48px;
    margin: 10px 20px;
    border-radius: ${({ error }) => (error ? '0px 0px' : '12px 12px')} 12px 12px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 728px) {
        width: 200px;
        padding: 20px 35px 18px;
    }
`

export const ErrorOverlay = styled.div`
    display: flex;
    margin: 0px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 600px;
    border-radius: 12px 12px 0px 0px;
    background-color: ${({ theme }) => theme.colors.grey['100']}99;
    padding: 18px;
    text-align: center;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
`

export const Logo = styled.img`
    align-self: center;
    height: 42px;
`

export const ForgotPassword = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey['100']};
    opacity: 0.6;
    a {
        color: ${({ theme }) => theme.colors.grey['100']};
    }
`

export const CreateAccount = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    max-height: 48px;
    border: 2px solid ${({ theme, error }) => theme.colors.grey['100']};
    font-size: 16px;
    color: ${({ theme, error }) => theme.colors.grey['100']};
    padding: 14px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`
export const Login = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    max-height: 48px;
    border: 2px solid ${({ theme }) => theme.colors.grey['100']};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey['100']};
    padding: 14px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`
export const textLogo = styled.p`
    height: 28px;
    margin: 24px 8px 40px 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey['100']};
`
export const List = styled.li`
    margin-left: 20px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.grey['100']};
    @media only screen and (max-width: 728px) {
        font-size: 12px;
    }
`
export const Text = styled.p`
    height: 28px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.grey['100']};
    @media only screen and (max-width: 728px) {
        font-size: 14px;
    }
`
