import { FunctionComponent } from 'react'
import * as styles from './style'

const VerifyRecoveryEmail: FunctionComponent = () => {
    return (
        <styles.Background>
            <styles.ContentPanel>
                <styles.Image src="/assets/img/ilustra-doctor.png" />
                <styles.Title>E-mail enviado!</styles.Title>
                <styles.Text>
                    {' '}
                    Agora é só entrar em seu e-mail e clicar no link para criar uma nova senha.
                </styles.Text>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default VerifyRecoveryEmail
