import styled from 'styled-components'
import Button from '../../components/Button'

export const Text = styled.p`
    height: 28px;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.grey['100']};
    @media only screen and (max-width: 728px) {
        font-size: 14px;
    }
`

export const ButtonModal = styled(Button)`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    margin: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 100%;
    max-height: 48px;
    border: 2px solid ${({ theme }) => theme.colors.green['100']};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.green['100']};
    padding: 24px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`
export const Title = styled.h3`
    font-weight: bold;
    align-items: center;
    float: center;
    color: ${({ theme }) => theme.colors.grey['100']};
    font-size: 28px;
`
