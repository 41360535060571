import React, { useState, FunctionComponent } from 'react'
import * as styles from './style'
import { InputAdornment, IconButton, Box } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Form, { Field } from 'rc-field-form'
import { LoginFormFields } from './interface'
import { MaterialInput } from '../../components/Input'
import { Link, useHistory } from 'react-router-dom'
import useLogin from '../../data/useLogin'
import qs from 'querystring'
import { useAuth } from '../../contexts/AuthContext'

const Login: FunctionComponent = () => {
    const [isPassword, setIsPassword] = useState<boolean>(true)

    const { authenticate } = useAuth()

    const { push } = useHistory()

    const { mutate: login, loading, error } = useLogin()

    const createAccount = () => {
        push('/cadastrar-cpf')
    }

    const submitLogin = async ({ cpf, password }: LoginFormFields): Promise<void> => {
        const data = await login(
            qs.stringify({
                username: cpf.replaceAll('.', '').replaceAll('-', ''),
                password,
                grant_type: 'password',
                client_id: 'ro.client',
                client_secret: 'secret',
                scope: 'api1'
            })
        )

        authenticate(data.access_token)

        push('/')
    }

    return (
        <styles.Background>
            {error && (
                <styles.ErrorOverlay id="error-overlay">
                    CPF e/ou senha inválidos
                </styles.ErrorOverlay>
            )}
            <styles.LoginPanel error={!!error}>
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>
                    Seja bem-vindo ao Portal de Privacidade da Docway{' '}
                </styles.textLogo>
                <Form onFinish={submitLogin}>
                    <Box mt={4}>
                        <Field name="cpf">
                            <MaterialInput id="CPF" label="CPF" fullWidth error={!!error} />
                        </Field>
                    </Box>
                    <Box mt={4}>
                        <Field name="password" rules={[{ required: true }]}>
                            <MaterialInput
                                id="password"
                                label="Senha"
                                fullWidth
                                error={!!error}
                                type={isPassword ? 'password' : 'text'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setIsPassword(!isPassword)}
                                            >
                                                {isPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box my={4}>
                        <styles.ForgotPassword>
                            <Link to="/esqueci-a-senha"> Esqueci a senha</Link>
                        </styles.ForgotPassword>
                    </Box>
                    <Box mt={4}>
                        <styles.Login error={!!error} type="submit" fullWidth loading={loading}>
                            Entrar
                        </styles.Login>
                    </Box>
                    <Box mt={2}>
                        <styles.CreateAccount onClick={() => createAccount()} fullWidth>
                            CRIAR CONTA
                        </styles.CreateAccount>
                    </Box>
                </Form>
            </styles.LoginPanel>
        </styles.Background>
    )
}

export default Login
