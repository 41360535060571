import { Box } from '@material-ui/core'
import { FunctionComponent } from 'react'
import * as styles from './styles'
import Dropdown from 'rc-dropdown'
import { useAuth } from '../../contexts/AuthContext'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router'

const Header: FunctionComponent = () => {
    const { user, logout } = useAuth()

    const { push } = useHistory()

    const menu = (
        <styles.MenuPanel>
            <styles.MenuButton onClick={() => push('/')}>Dados cadastrais</styles.MenuButton>
            <styles.MenuButton onClick={() => push('/solicitacoes')}>
                Solicitações
            </styles.MenuButton>
            <styles.MenuButton onClick={() => push('/trocar-senha')}>
                Alterar senha
            </styles.MenuButton>
            <styles.MenuButton onClick={() => logout()}>Sair</styles.MenuButton>
        </styles.MenuPanel>
    )

    return (
        <styles.Header>
            <styles.Container>
                <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <styles.Logo src="/assets/img/docway-logo.png" />

                    <Dropdown overlay={menu}>
                        <styles.UserButton>
                            {user?.name}
                            <MdKeyboardArrowDown size={25} />
                        </styles.UserButton>
                    </Dropdown>
                </Box>
            </styles.Container>
        </styles.Header>
    )
}

export default Header
