export const validateEmail = (email: string): boolean => {
    const regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(email)
}

export const validatePassword = (password: string): boolean => {
    const regex = new RegExp(
        // eslint-disable-next-line max-len
        '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\\-={}|\\\\:"";\'<>?,. \\/\\s]).{10,}$'
    )
    return regex.test(password)
}
