import styled from 'styled-components'

export const Background = styled.div`
    background-image: url('/assets/img/bg.png');
    background-position: center top;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    position: absolute;
    min-width: 1024px;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    @media only screen and (max-width: 728px) {
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
    }
`

export const ContentPanel = styled.div`
    width: 450px;
    height: 450px;
    padding: 60px 75px 48px;
    margin: 10px 20px;
    border-radius: ${({ error }) => (error ? '0px 0px' : '12px 12px')} 12px 12px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 728px) {
        width: 290px;
        padding: 20px 35px 18px;
    }
`

export const ErrorOverlay = styled.div`
    display: flex;
    margin: 0px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 600px;
    border-radius: 12px 12px 0px 0px;
    background-color: ${({ theme }) => theme.colors.grey['100']}99;
    padding: 18px;
    text-align: center;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
`

export const Continue = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    max-height: 48px;
    border: 2px solid ${({ theme }) => theme.colors.green['100']};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.green['100']};
    padding: 14px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`
export const Text = styled.p`
    height: 28px;
    margin: 24px 8px 40px 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey['100']};
`

export const Title = styled.h1`
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.green['100']};
`
export const VerifyInput = styled.div`
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px 20px 20px 1px;
    .ReactInputVerificationCode__container {
        width: calc(
            var(--ReactInputVerificationCode-itemWidth) * 3.6 +
                var(--ReactInputVerificationCode-itemSpacing) * (3.6 - 1)
        );
    }
    .ReactInputVerificationCode__item {
        border-radius: 12px;
        font-weight: 200;
        font-size: 24px;
        border: solid 1px ${({ theme }) => theme.colors.grey['100']};
    }
    @media only screen and (max-width: 728px) {
        padding: 15px 5px 10px 0px;
        .ReactInputVerificationCode__container {
            width: calc(
                var(--ReactInputVerificationCode-itemWidth) * 2.7 +
                    var(--ReactInputVerificationCode-itemSpacing) * (2.7 - 0)
            );
        }
        .ReactInputVerificationCode__item {
            --ReactInputVerificationCode-itemWidth: 3.5rem;
            --ReactInputVerificationCode-itemHeight: 3.5rem;
            margin-top: 20px;
            border-radius: 12px;
            font-weight: 200;
            font-size: 24px;
            border: solid 1px ${({ theme }) => theme.colors.grey['100']};
        }
    }
`

export const ForgotPassword = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey['100']};
    opacity: 0.6;
    a {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.grey['100']};
        text-decoration: underline;
    }
`
