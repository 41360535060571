import { useMutate } from 'restful-react'
import { LoginResponse } from './interface'

const useLogin = () => {
    return useMutate<LoginResponse, any, any, string>('POST', '/connect/token', {
        base: process.env.REACT_APP_AUTH_URL ?? 'https://service.docway.com.br/dev-auth',
        localErrorOnly: true,
        requestOptions: {
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        }
    })
}

export default useLogin
