import Loading from '../Loading'
import { TableProps } from './interfaces'
import * as styles from './styles'

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
function Table<T>({ children, loading = false, ...props }: TableProps<T>) {
    return (
        // @ts-ignore
        <styles.Table
            loading={{
                indicator: <Loading />,
                spinning: loading
            }}
            {...props}
        >
            {children}
        </styles.Table>
    )
}

export default Table
