import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
`

export const Panel = styled.div`
    width: 490px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    padding: 40px 20px;
    box-shadow: 0 1px 4px 0 rgba(174, 174, 174, 0.5);
`

export const Title = styled.p`
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0px;
`

export const Subtitle = styled.p`
    font-weight: bold;
    margin-bottom: 8px;
`
