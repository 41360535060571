import React, { FunctionComponent, useEffect } from 'react'
import * as styles from './style'
import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { useHistory } from 'react-router-dom'
import useLogin from '../../data/useLogin'
import useCheckRegisterUser from '../../data/useCheckRegisterUser'
import { RegisterCpfFormFields } from './interfaces'
import * as storage from '../../utils/storage'
import { toast } from 'react-toastify'

const RegisterCpf: FunctionComponent = () => {
    const { refetch: checkUserRegister, loading, data } = useCheckRegisterUser()

    const { push } = useHistory()

    const { error } = useLogin()
    const submitCpf = async ({ cpf }: RegisterCpfFormFields) => {
        await checkUserRegister({
            queryParams: {
                Cpf: cpf,
                DataType: 0
            }
        })
        storage.setItem('cpf', cpf)
    }

    useEffect(() => {
        if (data) {
            push('/cadastrar-nome')
        } else if (data == false) {
            toast.error('Ops! CPF não encontrado.')
        }
    }, [data])

    return (
        <styles.Background>
            {error && <styles.ErrorOverlay id="error-overlay">CPF inválido</styles.ErrorOverlay>}
            <styles.ContentPanel error={!!error}>
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>Seja bem-vindo ao Portal de Privacidade da Docway</styles.textLogo>
                <styles.Title>Qual seu CPF ?</styles.Title>
                <Form onFinish={submitCpf}>
                    <Box mt={4}>
                        <Field name="cpf">
                            <MaterialInput id="CPF" label="CPF" fullWidth error={!!error} />
                        </Field>
                    </Box>
                    <Box mt={6}>
                        <styles.Login error={!!error} loading={loading} type="submit" fullWidth>
                            Entrar
                        </styles.Login>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default RegisterCpf
