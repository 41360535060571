import { useGet } from 'restful-react'
import { CheckRegisterUserQueryParams } from './interfaces'

const useCheckRegisterUser = () => {
    return useGet<boolean, any, CheckRegisterUserQueryParams>('/user/check/register', {
        lazy: true
    })
}

export default useCheckRegisterUser
