import { useMutate } from 'restful-react'
import { CreateLgpdRequestBody, CreateLgpdRequestResponse } from './interfaces'

const useCreateNewLgpdRequest = () => {
    return useMutate<CreateLgpdRequestResponse, any, any, CreateLgpdRequestBody>(
        'POST',
        '/lgpd/create'
    )
}

export default useCreateNewLgpdRequest
