import { Box, Container, Grid } from '@material-ui/core'
import { FunctionComponent } from 'react'
import Loading from '../../components/Loading'
import useGetProfiles from '../../data/useGetProfiles'
import ProfilePanel from './profile-panel'

const Profile: FunctionComponent = () => {
    const { data: profiles, loading } = useGetProfiles()

    return (
        <>
            {loading && (
                <Box display="flex" justifyContent="center">
                    <Loading />
                </Box>
            )}
            {!loading && profiles && profiles.length === 0 && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <img src="/assets/img/no-lgpd-requests.svg" />
                    <Box mt={2}>
                        <p>Nenhum perfil encontrado</p>
                    </Box>
                </Box>
            )}
            <Container>
                <Grid container spacing={2} justifyContent="center">
                    {profiles &&
                        profiles.map(profile => (
                            <Grid key={profile.id} item xs={12} md={6}>
                                <ProfilePanel profile={profile} />
                            </Grid>
                        ))}
                </Grid>
            </Container>
        </>
    )
}

export default Profile
