import styled from 'styled-components'

const TextArea = styled.textarea`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 12px;
    border-color: rgba(112, 112, 112, 0.6);
    padding: 16px;
    outline: none;
    min-height: 160px;
    height: 160px;
`

export default TextArea
