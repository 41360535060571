import { Box, Container } from '@material-ui/core'
import { FunctionComponent, useEffect, useState } from 'react'
import Button from '../../components/Button'
import Table from '../../components/Table'
import { Table as AntdTable } from 'antd'
import * as styles from './styles'
import Loading from '../../components/Loading'
import dayjs from 'dayjs'
import useGetLgpdRequests from '../../data/useGetLgpdRequests'
import { LgpdRequest } from '../../types/LgpdRequest'
import { useHistory } from 'react-router'

const { Column } = AntdTable

const Requests: FunctionComponent = () => {
    const [requests, setRequests] = useState<LgpdRequest[]>([])

    const { push } = useHistory()

    const { mutate: getLgpdRequests, loading } = useGetLgpdRequests()

    const loadLgpdRequests = async () => {
        const response = await getLgpdRequests({
            Limit: 100000,
            CurrentPage: 0
        })

        setRequests(
            response.records.sort((a, b) => (dayjs(a.createdDate).isAfter(b.createdDate) ? -1 : 1))
        )
    }

    const getStatus = (status: number) => {
        switch (status) {
            case 1: {
                return <styles.Pending>Pendente</styles.Pending>
            }
            case 2: {
                return <styles.Solved>Resolvido</styles.Solved>
            }
        }
    }

    useEffect(() => {
        loadLgpdRequests()
    }, [])

    return (
        <Container>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" width="100%">
                <Button onClick={() => push('/nova-solicitacao')}>Nova Solicitação</Button>
            </Box>
            <styles.Container>
                <styles.Header>Minhas solicitações</styles.Header>
                <styles.Content>
                    {loading && (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Loading />
                        </Box>
                    )}
                    {!loading && requests.length === 0 && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <img src="/assets/img/no-lgpd-requests.svg" />
                            <Box mt={2}>
                                <p>No momento você não possui solicitações.</p>
                            </Box>
                        </Box>
                    )}
                    {!loading && requests.length > 0 && (
                        <Table dataSource={requests} rowKey="id" pagination={false}>
                            <Column title="ID" dataIndex="id" />
                            <Column title="Titular" dataIndex="requesterName" />
                            <Column title="Telefone" dataIndex="userPhoneNumber" />
                            <Column
                                title="Data Solicitação"
                                dataIndex="createdDate"
                                render={date => (date ? dayjs(date).format('DD/MM/YYYY') : '')}
                            />
                            <Column
                                title="Data Encerramento"
                                dataIndex="closedDate"
                                render={date => (date ? dayjs(date).format('DD/MM/YYYY') : '')}
                            />
                            <Column title="Tipo" dataIndex="type" />
                            <Column
                                title="Status"
                                dataIndex="status"
                                render={status => getStatus(status)}
                            />
                        </Table>
                    )}
                </styles.Content>
            </styles.Container>
        </Container>
    )
}

export default Requests
