import React, { FunctionComponent, useEffect } from 'react'
import * as styles from './style'
import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { useHistory } from 'react-router-dom'
import useLogin from '../../data/useLogin'
import useCheckRegisterUser from '../../data/useCheckRegisterUser'
import { RegisterBirthDateFormFields } from './interfaces'
import * as storage from '../../utils/storage'
import dayjs from 'dayjs'

const RegisterBirthDate: FunctionComponent = () => {
    const { refetch: checkUserRegister, loading, data } = useCheckRegisterUser()

    const { push } = useHistory()

    const { error } = useLogin()

    let dataErro = storage.getItem('dataErro')

    const submitBirthDate = async ({ birthDate, ...rest }: RegisterBirthDateFormFields) => {
        const cpf = storage.getItem('cpf')
        const name = storage.getItem('name')

        await checkUserRegister({
            queryParams: {
                Cpf: cpf || '',
                Name: name || 'name',
                BirthDate: dayjs(birthDate).toISOString(),
                DataType: 2
            }
        })

        storage.setItem('birthDate', dayjs(birthDate).toISOString())
    }

    const setDataError = () => {
        if (data == false) {
            dataErro = '1'
            storage.setItem('dataErro', dataErro)
            push('/cadastrar-email')
        }
    }

    useEffect(() => {
        if (data) {
            push('/cadastrar-email')
        } else {
            setDataError()
        }
    }, [data])

    return (
        <styles.Background>
            {error && (
                <styles.ErrorOverlay id="error-overlay">
                    Data de nascimento inválida.
                </styles.ErrorOverlay>
            )}
            <styles.ContentPanel error={!!error}>
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>Seja bem-vindo ao Portal de Privacidade da Docway</styles.textLogo>
                <styles.Title>Qual sua data de nascimento ?</styles.Title>
                <Form onFinish={submitBirthDate}>
                    <Box mt={4}>
                        <Field name="birthDate">
                            <MaterialInput
                                id="birthdate"
                                label="Data de nascimento"
                                type="date"
                                data-date-format="dd/mm/yyyy"
                                fullWidth
                                error={!!error}
                            />
                        </Field>
                    </Box>

                    <Box mt={6}>
                        <styles.Login
                            error={!!error}
                            type="submit"
                            fullWidth
                            loading={loading}
                            onclick={setDataError}
                        >
                            Continuar
                        </styles.Login>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default RegisterBirthDate
