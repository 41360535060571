import styled from 'styled-components'

export const Background = styled.div`
    background-image: url('/assets/img/bg.png');
    background-position: center top;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    position: absolute;
    min-width: 1024px;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
`

export const Panel = styled.div`
    width: 490px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    padding: 60px 60px;
    box-shadow: 0 1px 4px 0 rgba(174, 174, 174, 0.5);
`

export const Title = styled.p`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.green['100']};
`

export const Subtitle = styled.p`
    text-align: center;
`

export const List = styled.ul`
    margin-top: 8px;
    padding-left: 20px;

    & li:not(:last-child) {
        margin-bottom: 4px;
    }

    & li span {
        position: relative;
        left: 10px;
    }
`
