import { Box, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Form, { Field } from 'rc-field-form'
import { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import { MaterialInput } from '../../components/Input'
import { useAuth } from '../../contexts/AuthContext'
import useChangePassword from '../../data/useChangePassword'
import { ChangePasswordFormFields } from './interfaces'
import * as styles from './styles'

const ChangePassword: FunctionComponent = () => {
    const [currentPassword, setCurrentPassword] = useState<boolean>(true)
    const [newPassword, setNewPassword] = useState<boolean>(true)

    const { user } = useAuth()

    const { push } = useHistory()

    const { mutate: changePassword, loading } = useChangePassword()

    const submitChangePassword = async ({
        currentPassword,
        newPassword
    }: ChangePasswordFormFields) => {
        await changePassword({
            Email: user?.email || '',
            Token: currentPassword,
            Password: newPassword
        })

        push('/trocar-senha-sucesso')
    }

    return (
        <styles.Container>
            <styles.Panel>
                <Form onFinish={submitChangePassword}>
                    <styles.Title>Digite sua nova senha</styles.Title>
                    <Box mt={2} mb={4}>
                        <p>Por motivo de segurança sua nova senha não pode ser igual a anterior.</p>
                    </Box>
                    <Box>
                        <Field name="currentPassword" rules={[{ required: true }]}>
                            <MaterialInput
                                label="Senha atual"
                                fullWidth
                                type={currentPassword ? 'password' : 'text'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setCurrentPassword(!currentPassword)}
                                            >
                                                {currentPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box mt={4}>
                        <Field name="newPassword" rules={[{ required: true }]}>
                            <MaterialInput
                                label="Nova senha"
                                fullWidth
                                type={newPassword ? 'password' : 'text'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setNewPassword(!newPassword)}
                                            >
                                                {newPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box mt={6}>
                        <p>No mínimo 10 caracteres, sendo</p>
                        <styles.List>
                            <li>
                                <span>Caractere alfabético maiúsculo e minúsculo</span>
                            </li>
                            <li>
                                <span>Caractere numérico</span>
                            </li>
                            <li>
                                <span>Caractere especial</span>
                            </li>
                        </styles.List>
                    </Box>
                    <Box mt={6}>
                        <Button type="submit" fullWidth loading={loading}>
                            Salvar
                        </Button>
                    </Box>
                </Form>
            </styles.Panel>
        </styles.Container>
    )
}

export default ChangePassword
