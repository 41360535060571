import { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import * as styles from './styles'
import { IoMdClose } from 'react-icons/io'
import { Colors } from '../../constants/Colors'
import { Box } from '@material-ui/core'

const NewRequestSuccess: FunctionComponent = () => {
    const { push } = useHistory()

    return (
        <styles.Container>
            <styles.Panel>
                <styles.CloseButton onClick={() => push('/solicitacoes')}>
                    <IoMdClose fill={Colors.green['100']} size={30} />
                </styles.CloseButton>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <styles.Title>Solicitação enviada com sucesso</styles.Title>
                    <p>Em breve retornaremos com a resposta da sua solicitação</p>
                </Box>
            </styles.Panel>
        </styles.Container>
    )
}

export default NewRequestSuccess
