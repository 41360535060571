import styled from 'styled-components'
import { Table as AntdTable } from 'antd'

export const Table = styled(AntdTable)`
    & table {
        border-collapse: collapse !important;
    }

    & th {
        color: ${({ theme }) => theme.colors.grey['100']} !important;
        font-size: 16px !important;
        font-weight: bold !important;
        background-color: ${({ theme }) => theme.colors.white} !important;
    }

    & th::before {
        width: 0px !important;
    }

    & td {
        font-size: 16px !important;
    }

    & thead {
        border-width: 0px;
        border-bottom-width: 2px !important;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.grey['100']};
    }

    & * {
        background-color: ${({ theme }) => theme.colors.white} !important;
    }
`
