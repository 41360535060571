import { CircularProgress, withStyles } from '@material-ui/core'
import { Colors } from '../../constants/Colors'

export default withStyles({
    root: {
        '& .MuiCircularProgress-circle': {
            color: Colors.green['100']
        }
    }
})(CircularProgress)
