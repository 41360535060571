import React, { FunctionComponent, useEffect, useState } from 'react'
import * as styles from './style'
import ReactInputVerificationCode from 'react-input-verification-code'
import { Box } from '@material-ui/core'
import * as storage from '../../utils/storage'
import { useHistory } from 'react-router-dom'
import useConfirmCode from '../../data/useConfirmCode'
import useSendConfirmationCode from '../../data/useSendConfirmationCode'
import { toast } from 'react-toastify'

const VerifyCode: FunctionComponent = () => {
    const [value, setValue] = useState<string>('')

    const { push } = useHistory()

    const email = storage.getItem('email') || ''

    const { refetch: confirmCode, loading, data } = useConfirmCode(value, email)

    const { mutate: sendConfirmationCode } = useSendConfirmationCode()

    const ResendCode = async ({ email }: any) => {
        const name = storage.getItem('name')

        await sendConfirmationCode({
            Email: email,
            Name: name || ''
        })

        push('/verifica-codigo')

        toast.success('Código reenviado, favor verifique novamente seu e-mail!')
    }
    const submitConfirmCode = async () => {
        await confirmCode()
    }

    useEffect(() => {
        if (data) {
            push('/cadastrar-senha')
        }
    }, [data])

    return (
        <styles.Background>
            <styles.ContentPanel>
                <styles.Title>Código de validação</styles.Title>
                <Box>
                    <styles.Text>
                        Enviamos o código de verificação de 4 dígitos para{' '}
                        {email.substring(0, 2) + '*****' + email.substring(email.indexOf('@'))}
                    </styles.Text>
                    <styles.VerifyInput>
                        <ReactInputVerificationCode
                            onChange={e => {
                                setValue(e)
                            }}
                            value={value}
                            placeholder=""
                        />
                    </styles.VerifyInput>
                    <Box my={2}>
                        <styles.ForgotPassword>
                            Não recebeu o código ?{' '}
                            <a onClick={() => ResendCode({ email })}> Reenviar o código</a>
                        </styles.ForgotPassword>
                    </Box>
                    <Box mt={2}>
                        <styles.Continue
                            type="submit"
                            loading={loading}
                            fullWidth
                            onClick={() => submitConfirmCode()}
                        >
                            SALVAR
                        </styles.Continue>
                    </Box>
                </Box>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default VerifyCode
