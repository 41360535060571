import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Layout from '../Layout'
import { PrivateRouteProps } from './interface'

const PrivateRoute = ({ roles, component: Component, ...props }: PrivateRouteProps) => {
    const { isAuthenticated, user } = useAuth()

    if (isAuthenticated) {
        // TODO: checks user role
        if (roles && roles.length > 0) {
            return (
                <Route
                    render={componentProps => (
                        <Layout>
                            {/*
                    // @ts-ignore */}
                            <Component {...componentProps} />
                        </Layout>
                    )}
                    {...props}
                />
            )
        } else {
            return (
                <Route
                    render={componentProps => (
                        <Layout>
                            {/*
                    // @ts-ignore */}
                            <Component {...componentProps} />
                        </Layout>
                    )}
                    {...props}
                />
            )
        }
    } else {
        return <Redirect to="/login" />
    }
}

export default PrivateRoute
