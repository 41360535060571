import styled, { css } from 'styled-components'
import { ButtonProps } from './interface'

export const Button = styled.button<ButtonProps>`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    max-height: 48px;
    border: 2px solid
        ${({ theme, error }) => (error ? theme.colors.grey['100'] : theme.colors.green['100'])};
    font-size: 16px;
    color: ${({ theme, error }) => (error ? theme.colors.grey['100'] : theme.colors.green['100'])};
    padding: 14px 24px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    font-family: 'Maven';
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    & *:not(:first-child) {
        margin-left: 8px;
    }
    & * {
        color: ${({ theme, error }) =>
            error ? theme.colors.grey['100'] : theme.colors.green['100']};
    }
    & p {
        margin-bottom: 0px;
    }
    ${({ buttonType, ...props }) => buttonType === 'contained' && Contained()};
`

export const Contained = () => css`
    border: none;
    box-shadow: 0 1px 4px 0 rgba(112, 112, 112, 0.4);
    color: ${({ theme }) => theme.colors.grey['100']};
    font-weight: bold;
    & * {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.grey['100']};
    }
`
