import { FunctionComponent } from 'react'
import Header from '../Header'
import * as styles from './styles'

const Layout: FunctionComponent = ({ children }) => {
    return (
        <>
            <Header />
            <styles.ContentWrapper>{children}</styles.ContentWrapper>
        </>
    )
}

export default Layout
