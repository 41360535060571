import styled from 'styled-components'
import { Container as MaterialContainer } from '@material-ui/core'

export const Header = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1px 8px 0 rgba(112, 112, 112, 0.2);
    height: 60px;
`

export const Container = styled(MaterialContainer)`
    height: 100%;
`

export const Logo = styled.img`
    height: 28px;
    width: auto;
`

export const UserButton = styled.div`
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    border-radius: 4px;
    cursor: pointer;

    & > * {
        margin-left: 10px;
    }
`

export const MenuPanel = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    box-shadow: 0 1px 8px 0 rgba(112, 112, 112, 0.2);
    width: 200px;
`

export const MenuButton = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: left;
    border-radius: 12px;
`
