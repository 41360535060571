import React, { useState, FunctionComponent } from 'react'
import * as styles from './style'
import { InputAdornment, IconButton, Box } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { useHistory } from 'react-router-dom'
import useCreateUser from '../../data/useCreateUser'
import * as storage from '../../utils/storage'
import { RegisterPasswordFormFields } from './interfaces'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

const RegisterPassword: FunctionComponent = () => {
    const [isPassword, setIsPassword] = useState<boolean>(true)

    const { push } = useHistory()

    const { mutate: createUser, loading } = useCreateUser()

    const submitPassword = async ({ password }: RegisterPasswordFormFields) => {
        const email = storage.getItem('email') || ''
        const cpf = storage.getItem('cpf') || ''
        const name = storage.getItem('name') || ''
        const birthDate = storage.getItem('birthDate') || new Date().toISOString()

        const response = await createUser({
            email,
            username: name,
            userlogin: cpf,
            password,
            birthdate: dayjs(birthDate).format('DD/MM/YYYYY')
        })

        toast.success('Conta criada com sucesso!')

        push('/login')
    }

    return (
        <styles.Background>
            <styles.LoginPanel>
                <styles.Logo src="/assets/img/docway-logo.png" />
                <styles.textLogo>Seja bem-vindo ao Portal de Privacidade da Docway</styles.textLogo>
                <styles.Title>Crie sua senha de acesso.</styles.Title>
                <Form onFinish={submitPassword}>
                    <Box mt={4}>
                        <Field name="password" rules={[{ required: true }]}>
                            <MaterialInput
                                id="password"
                                label="Senha"
                                fullWidth
                                type={isPassword ? 'password' : 'text'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setIsPassword(!isPassword)}
                                            >
                                                {isPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box my={4}>
                        <styles.Text>No mínimo 10 caracteres, sendo</styles.Text>
                        <styles.List>Caractere alfabético maiúsculo e minúsculo</styles.List>
                        <styles.List>Caractere numérico</styles.List>
                        <styles.List>Carctere especial</styles.List>
                    </Box>

                    <Box mt={2}>
                        <styles.CreateAccount type="submit" loading={loading} fullWidth>
                            CRIAR
                        </styles.CreateAccount>
                    </Box>
                </Form>
            </styles.LoginPanel>
        </styles.Background>
    )
}

export default RegisterPassword
