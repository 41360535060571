import { Box } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { ProfilePanelProps } from './interfaces'
import dayjs from 'dayjs'
import * as styles from './styles'
import getProfileLabel from '../../../utils/getProfileLabel'

const ProfilePanel: FunctionComponent<ProfilePanelProps> = ({ profile }) => {
    const handlePrivatePolicy = () => {
        window.open('https://docway.com.br/politica-de-privacidade/', '_blank')
    }

    const handleTerms = () => {
        window.open('https://docway.com.br/termo-de-uso/', '_blank')
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            borderRadius="8px"
            boxShadow="0 1px 4px 0 rgba(174, 174, 174, 0.5)"
        >
            <styles.Header>{`Informações cadastrais como “${getProfileLabel(
                profile.userType
            )}”`}</styles.Header>
            <styles.Content>
                <Box mb={3}>
                    <styles.Label>Nome</styles.Label>
                    <styles.Value>{profile.name}</styles.Value>
                </Box>
                <Box mb={3}>
                    <styles.Label>CPF</styles.Label>
                    <styles.Value>{profile.cpf}</styles.Value>
                </Box>
                {profile.rg && (
                    <Box mb={3}>
                        <styles.Label>RG</styles.Label>
                        <styles.Value>{profile.rg}</styles.Value>
                    </Box>
                )}
                <Box mb={3}>
                    <styles.Label>Data de nascimento</styles.Label>
                    <styles.Value>{dayjs(profile.dateOfBirth).format('DD/MM/YYYY')}</styles.Value>
                </Box>
                <Box mb={3}>
                    <styles.Label>Sexo</styles.Label>
                    <styles.Value>{profile.gender === 0 ? 'Masculino' : 'Feminino'}</styles.Value>
                </Box>
                {profile.cardNumber && (
                    <Box mb={3}>
                        <styles.Label>Matrícula</styles.Label>
                        <styles.Value>{profile.cardNumber}</styles.Value>
                    </Box>
                )}
                <Box mb={3}>
                    <styles.Label>Email</styles.Label>
                    <styles.Value>{profile.email}</styles.Value>
                </Box>
                {profile.phoneNumber && (
                    <Box mb={3}>
                        <styles.Label>Telefone</styles.Label>
                        <styles.Value>{profile.phoneNumber}</styles.Value>
                    </Box>
                )}
                {profile.cliente && (
                    <Box mb={3}>
                        <styles.Label>Convênio</styles.Label>
                        <styles.Value>{profile.cliente}</styles.Value>
                    </Box>
                )}
                {profile.fullAddress && (
                    <Box mb={3}>
                        <styles.Label>Endereço</styles.Label>
                        <styles.Value>{profile.fullAddress}</styles.Value>
                    </Box>
                )}
                <Box mt={5}>
                    <styles.Title>Informações legais</styles.Title>
                </Box>
            </styles.Content>
            <styles.Footer>
                <Box mb={2}>
                    <styles.LegalInformation onClick={() => handleTerms()}>
                        Termos de uso
                    </styles.LegalInformation>
                </Box>
                <styles.Divider />
                <Box mt={2}>
                    <styles.LegalInformation onClick={() => handlePrivatePolicy()}>
                        Política de privacidade
                    </styles.LegalInformation>
                </Box>
            </styles.Footer>
        </Box>
    )
}

export default ProfilePanel
