import { FunctionComponent } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Login from './pages/login'
import VerifyRecoveryEmail from './pages/verify-recovery-email'
import ForgotPassword from './pages/forgot-password'
import VerifyCode from './pages/verify-code'
import NewPassword from './pages/new-password'
import PrivateRoute from './components/PrivateRoute'
import Profile from './pages/profile'
import ChangePassword from './pages/change-password'
import Requests from './pages/requests'
import RegisterPassword from './pages/register-password'
import RegisterName from './pages/register-name'
import RegisterBirthDate from './pages/register-birth-date'
import RegisterCpf from './pages/register-cpf'
import RegisterEmail from './pages/register-email'
import NewRequest from './pages/new-request'
import NewRequestSuccess from './pages/new-request-success'
import ChangePasswordSuccess from './pages/change-password-success'
import NotFound from './pages/error'
import ResetPassword from './pages/reset-password'

const Router: FunctionComponent = () => {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path="/" component={Profile} />
                <PrivateRoute exact path="/solicitacoes" component={Requests} />
                <PrivateRoute exact path="/trocar-senha" component={ChangePassword} />
                <PrivateRoute
                    exact
                    path="/trocar-senha-sucesso"
                    component={ChangePasswordSuccess}
                />
                <PrivateRoute exact path="/nova-solicitacao" component={NewRequest} />
                <PrivateRoute
                    exact
                    path="/nova-solicitacao-sucesso"
                    component={NewRequestSuccess}
                />
                <Route exact path="/login" component={Login} />
                <Route exact path="/resetar-senha" component={ResetPassword} />
                <Route exact path="/verifique-seu-email" component={VerifyRecoveryEmail} />
                <Route exact path="/verifica-codigo" component={VerifyCode} />
                <Route exact path="/esqueci-a-senha" component={ForgotPassword} />
                <Route exact path="/nova-senha" component={NewPassword} />
                <Route exact path="/cadastrar-senha" component={RegisterPassword} />
                <Route exact path="/cadastrar-nome" component={RegisterName} />
                <Route exact path="/cadastrar-data-nasc" component={RegisterBirthDate} />
                <Route exact path="/cadastrar-cpf" component={RegisterCpf} />
                <Route exact path="/cadastrar-email" component={RegisterEmail} />
                <Route exact path="/404" component={NotFound} />
                <Route exact path="*" component={NotFound} />
                <Redirect to="/login" />
            </Switch>
        </BrowserRouter>
    )
}

export default Router
