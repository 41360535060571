export enum UserType {
    Patient = 0,
    Collaborator = 1,
    Doctor = 2,
    Clinic = 3,
    Adminstrator = 4,
    Investor = 5,
    CollaboratorTech = 6,
    CollaboratorCommunication = 7,
    CollaboratorMarketing = 8,
    CollaboratorFinancial = 9,
    Financial = 10,
    Supervisor = 11,
    Agent = 12,
    Register = 13,
    Psychologist = 14
}
