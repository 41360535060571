import { UserType } from '../types/UserType'

export default (userType: UserType) => {
    switch (userType) {
        case UserType.Patient: {
            return 'Paciente'
        }
        case UserType.Collaborator: {
            return 'Colaborador'
        }
        case UserType.Doctor: {
            return 'Médico'
        }
        case UserType.Clinic: {
            return 'Clínico'
        }
        case UserType.Adminstrator: {
            return 'Administrador'
        }
        case UserType.Investor: {
            return 'Investidor'
        }
        case UserType.CollaboratorTech: {
            return 'Colaborador'
        }
        case UserType.CollaboratorCommunication: {
            return 'Colaborador'
        }
        case UserType.CollaboratorMarketing: {
            return 'Colaborador'
        }
        case UserType.CollaboratorFinancial: {
            return 'Colaborador'
        }
        case UserType.Financial: {
            return 'Financeiro'
        }
        case UserType.Supervisor: {
            return 'Supervisor'
        }
        case UserType.Agent: {
            return 'Agente'
        }
        case UserType.Register: {
            return 'Registro'
        }
        case UserType.Psychologist: {
            return 'Psicólogo'
        }
        default: {
            return 'Sem tipo'
        }
    }
}
