import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 20px;
    box-shadow: 0 1px 4px 0 rgba(174, 174, 174, 0.5);
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
`

export const Header = styled.div`
    padding: 16px 20px;
    font-weight: bold;
    font-size: 20px;
`

export const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: auto;
    max-width: 100%;
    padding: 16px 20px;
    border-top: 1px solid #f1f1f1;
    border-radius: 0px 0px 8px 8px;
`

export const Pending = styled.p`
    margin-bottom: 0px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.yellow['100']};
`

export const Solved = styled.p`
    margin-bottom: 0px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.green['100']};
`
