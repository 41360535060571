import { FunctionComponent } from 'react'
import { ButtonProps } from './interface'
import Loading from '../Loading'
import * as styles from './style'

const Button: FunctionComponent<ButtonProps> = ({ children, loading, onClick, ...props }) => {
    return (
        <styles.Button {...props} onClick={onClick}>
            {loading ? <Loading size={20} /> : <>{children}</>}
        </styles.Button>
    )
}

export default Button
