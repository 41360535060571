import React, { useState, FunctionComponent } from 'react'
import * as styles from './style'
import { InputAdornment, IconButton, Box } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Form, { Field } from 'rc-field-form'
import { MaterialInput } from '../../components/Input'
import { validatePassword } from '../../helpers/validator'

const NewPassword: FunctionComponent = () => {
    const [isPassword, setIsPassword] = useState<boolean>(true)

    const [newPassword, setNewPassword] = useState({
        value: '',
        isValid: false,
        touched: false
    })
    const [oldPassword, setOldPassword] = useState({
        value: '',
        isValid: true,
        touched: false
    })

    return (
        <styles.Background>
            <styles.ContentPanel>
                <styles.Title>Digite sua nova senha </styles.Title>
                <styles.Subtitle>
                    Por motivos de segurança sua nova senha não pode ser igual a anterior.
                </styles.Subtitle>
                <Form>
                    <Box mt={4}>
                        <Field name="password" rules={[{ required: true }]}>
                            <MaterialInput
                                id="password"
                                label="Senha"
                                fullWidth
                                type={isPassword ? 'password' : 'text'}
                                onBlur={() => {
                                    setNewPassword({
                                        ...newPassword,
                                        isValid: validatePassword(newPassword.value),
                                        touched: true
                                    })
                                }}
                                error={!newPassword.isValid}
                                helperText="A senha deve atender requisitos necessários."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setIsPassword(!isPassword)}
                                            >
                                                {isPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box>
                        <styles.Text>No mínimo 10 caracteres, sendo</styles.Text>
                        <styles.List>Caractere alfabético maiúsculo e minúsculo</styles.List>
                        <styles.List>Caractere numérico</styles.List>
                        <styles.List>Carctere especial</styles.List>
                    </Box>
                    <Box mt={2}>
                        <styles.Continue type="submit" fullWidth>
                            {' '}
                            SALVAR{' '}
                        </styles.Continue>
                    </Box>
                </Form>
            </styles.ContentPanel>
        </styles.Background>
    )
}

export default NewPassword
