import { createGlobalStyle } from 'styled-components'
import { Colors } from '../constants/Colors'

export default createGlobalStyle`
    @font-face {
        font-family: 'Maven';
        src: url('/assets/fonts/maven.ttf') format('truetype');
    }

    * {
        font-family: 'Maven' !important;
        color: ${Colors.grey['100']};
        margin: 0px;
        font-size: 16px;
    }

    html, body, #root {
        width: 100%;
        min-height: 100vh;
        padding: 0px;
        background-color: #F4F4F4;
    }
`
