import styled from 'styled-components'

export const Header = styled.div`
    width: auto;
    max-width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 16px 20px;
    border-radius: 8px 8px 0px 0px;
    font-weight: bold;
    font-size: 20px;
    text-overflow: clip;
`

export const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: auto;
    max-width: 100%;
    padding: 16px 20px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
`

export const Footer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0px 0px 8px 8px;
    padding: 16px 20px;
`

export const Label = styled.p`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
`

export const Value = styled.p`
    font-size: 16px;
    margin-bottom: 0px;
`

export const Title = styled.p`
    font-weight: bold;
    font-size: 20px;
`

export const Divider = styled.div`
    background-color: rgba(112, 112, 112, 0.2);
    height: 1px;
`

export const LegalInformation = styled.p`
    color: ${({ theme }) => theme.colors.grey['100']};
    font-size: 16px;
    text-decoration: solid ${({ theme }) => theme.colors.grey['100']};
    &:hover {
        color: ${({ theme }) => theme.colors.green['100']};
        text-decoration: solid ${({ theme }) => theme.colors.green['100']};
    }
`
