import React, { FunctionComponent } from 'react'
import * as styles from './style'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const NotFound: FunctionComponent = () => {
    const { push } = useHistory()

    const createAccount = () => {
        push('/login')
    }
    return (
        <styles.ContentPanel>
            <styles.Elements>
                <styles.Title>Oops!</styles.Title>
                <styles.Subtitle>Pagina não encontrada.</styles.Subtitle>
                <Box mt={2}>
                    <styles.CreateAccount onClick={() => createAccount()}>
                        VOLTAR AO INICIO
                    </styles.CreateAccount>
                </Box>
            </styles.Elements>
            <styles.Elements>
                <styles.Img src="/assets/img/doctors.png" />
            </styles.Elements>
        </styles.ContentPanel>
    )
}

export default NotFound
