import Router from './Router'
import { ThemeProvider } from 'styled-components'
import theme from './config/themes'
import Providers from './Providers'
import GlobalStyles from './config/GlobalStyles'
import { ToastContainer } from 'react-toastify'
import { Colors } from './constants/Colors'

const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ToastContainer bodyStyle={{ color: Colors.white }} />
                <Providers>
                    <Router />
                </Providers>
            </ThemeProvider>
        </>
    )
}

export default App
