import { Box, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Form, { Field } from 'rc-field-form'
import { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Button from '../../components/Button'
import { MaterialInput } from '../../components/Input'
import useResetPassword from '../../data/useResetPassword'
import useQuery from '../../utils/useQuery'
import { ResetPasswordFormFields } from './interfaces'
import * as styles from './styles'

const ResetPassword: FunctionComponent = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const query = useQuery()
    const { push } = useHistory()

    const { mutate: resetPassword, loading } = useResetPassword()

    const submitResetPassword = async ({ password }: ResetPasswordFormFields) => {
        const [email, token] = decodeURIComponent(escape(atob(query.get('token') ?? ''))).split(';')

        await resetPassword({
            Email: email,
            Password: password,
            Token: token
        })

        push('/login')

        toast.success('Senha alterada com sucesso!')
    }

    return (
        <styles.Background>
            <styles.Panel>
                <Form onFinish={submitResetPassword}>
                    <styles.Title>Digite sua nova senha</styles.Title>
                    <Box mt={2} mb={4}>
                        <styles.Subtitle>
                            Por motivo de segurança sua nova senha não pode ser igual a anterior.
                        </styles.Subtitle>
                    </Box>
                    <Box>
                        <Field name="password" rules={[{ required: true }]}>
                            <MaterialInput
                                label="Nova senha"
                                fullWidth
                                type={!showPassword ? 'password' : 'text'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Field>
                    </Box>
                    <Box mt={6}>
                        <p>No mínimo 10 caracteres, sendo</p>
                        <styles.List>
                            <li>
                                <span>Caractere alfabético maiúsculo e minúsculo</span>
                            </li>
                            <li>
                                <span>Caractere numérico</span>
                            </li>
                            <li>
                                <span>Caractere especial</span>
                            </li>
                        </styles.List>
                    </Box>
                    <Box mt={6}>
                        <Button type="submit" fullWidth loading={loading}>
                            Salvar
                        </Button>
                    </Box>
                </Form>
            </styles.Panel>
        </styles.Background>
    )
}

export default ResetPassword
