import { FunctionComponent } from 'react'
import { toast } from 'react-toastify'
import { RestfulProvider } from 'restful-react'
import { useAuth } from '../../contexts/AuthContext'
import * as storage from '../../utils/storage'

const ApiProvider: FunctionComponent = ({ children }) => {
    const { logout } = useAuth()

    const onRequestError = (error: any) => {
        if (error.status === 401) {
            logout()
        } else {
            if (error?.data?.error) {
                toast.error(error.data.error)
            } else if (error?.data?.errorMessage) {
                toast.error(error?.data?.errorMessage)
            } else {
                toast.error(error.message)
            }
        }
    }

    const requestOptions = () => {
        const token = storage.getItem('token')

        if (token) {
            return {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }

        return {}
    }

    return (
        <RestfulProvider
            base={process.env.REACT_APP_API_URL ?? 'https://service.docway.com.br/api'}
            onError={onRequestError}
            requestOptions={requestOptions}
        >
            {children}
        </RestfulProvider>
    )
}

export default ApiProvider
