import { Box } from '@material-ui/core'
import Form, { Field } from 'rc-field-form'
import { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import CheckboxGroup from '../../components/CheckboxGroup'
import TextArea from '../../components/TextArea'
import { useAuth } from '../../contexts/AuthContext'
import useCreateNewLgpdRequest from '../../data/useCreateNewLgpdRequest'
import { NewRequestFormFields } from './interfaces'
import * as styles from './styles'

const NewRequest: FunctionComponent = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    const { user } = useAuth()

    const { push } = useHistory()

    const { mutate: createNewLgpdRequest, loading } = useCreateNewLgpdRequest()

    const submitNewRequest = async ({ description, types }: NewRequestFormFields) => {
        await createNewLgpdRequest({
            UserId: user?.id ?? '',
            RequesterId: user?.id ?? '',
            RequesterName: user?.name ?? '',
            PartnerId: 2,
            Description: description,
            Type: types.join(', ')
        })

        push('/nova-solicitacao-sucesso')
    }

    return (
        <styles.Container>
            <styles.Panel>
                <Form
                    onFinish={submitNewRequest}
                    onValuesChange={(a, values) => setIsDisabled(!values.description)}
                >
                    <styles.Title>Proteção de Dados (LGPD)</styles.Title>
                    <Box mt={2} mb={3}>
                        <p>
                            Solicite ações relacionadas aos seus dados cadastrados na Docway. Você
                            pode: solicitar o registro de dados, pedir informações sobre a
                            utilização deles ou solicitar a exclusão.
                        </p>
                    </Box>
                    <styles.Subtitle>
                        Qual a classificação da solicitação? (Opcional)
                    </styles.Subtitle>
                    <p>
                        Você pode escolher mais de um ou, se não souber a classificação, deixe em
                        branco que classificaremos para você.
                    </p>
                    <Field name="types">
                        <CheckboxGroup
                            options={[
                                {
                                    label: 'Expurgo',
                                    value: 'Expurgo'
                                },
                                {
                                    label: 'Eliminação',
                                    value: 'Eliminação'
                                },
                                {
                                    label: 'Consulta',
                                    value: 'Consulta'
                                },
                                {
                                    label: 'Bloqueio',
                                    value: 'Bloqueio'
                                },
                                {
                                    label: 'Correção',
                                    value: 'Correção'
                                },
                                {
                                    label: 'Portabilidade',
                                    value: 'Portabilidade'
                                }
                            ]}
                        />
                    </Field>
                    <Box mt={2}>
                        <Field name="description" rules={[{ required: true }]}>
                            <TextArea />
                        </Field>
                    </Box>
                    <Box mt={4}>
                        <Button loading={loading} fullWidth type="submit" error={isDisabled}>
                            Solicitar
                        </Button>
                    </Box>
                </Form>
            </styles.Panel>
        </styles.Container>
    )
}

export default NewRequest
