const LOCAL_STORAGE_PREFIX = '@privacy-web'

export const setItem = (key: string, data: string): void => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}/${key}`, data)
}

export const getItem = <T = string>(key: string): T | null => {
    let value

    try {
        value = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}/${key}`)

        if (value) {
            value = JSON.parse(value)
        }
    } catch (error) {
        console.error(error)
    }

    return value || null
}

export const clear = (): void => {
    localStorage.clear()
}
